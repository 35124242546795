.footer {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 3rem 1rem;
}

.footer .container {
  max-width: 1240px;
  margin: auto;
  text-align: center;
}

.footer .menu h2 {
  color: #fff;
}

.footer ul.footer-menu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.footer li {
  padding: 1rem;
}

.footer li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer li a:hover {
  color: var(--secondary-color);
  padding-bottom: 12px;
  border-bottom: 3px solid var(--primary-color);
}

.bottom {
  text-align: center;
  padding-top: 1rem;
}

.line {
  margin-bottom: 1rem;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 50%);
}

.form-group {
  margin-bottom: 1rem;
}

.formContainer {
  border: 1px solid #fff;
  padding: 1rem;
  margin: 1rem 0.5rem;
}

.form-group label {
  color: #fff;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
}

.submit-button {
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  border: 1pt solid #fff;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding: 12px 26px;
  font-size: 20px;
}

.submit-button:hover {
  background-color: var(--primary-color);
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 0.5rem;
  }

  .formContainer {
    margin: 1rem;
  }

  .footer ul.footer-menu {
    flex-direction: column;
    text-align: center;
  }

  .footer li {
    padding: 0.5rem 0;
  }
}