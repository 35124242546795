.about {
    background: #fff;
    margin: auto;
    padding: 8rem 1rem 0 1rem;
    width: 100%;
}

.about .container {
    width: 100%;
    max-width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.about .image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.about .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .content p:first-child {
    margin: 2rem 0;
}

.about .content p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.about .content p {
    margin-top: 1rem;
    line-height: 1.5;
    font-size: 16px;
}

@media screen and (max-width: 940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .image img {
        width: 80%;
    }

    .about .content {
        padding: 0;
    }
}